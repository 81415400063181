// All API Call Status
export const API_STATUS = {
	COMPLETED: 'completed',
	REJECTED: 'rejected',
	PENDING: 'pending',
	LOADING: 'loading',
	CLEAR: 'clear'
};

export const ROLES = {
	ARTDIRECTOR: 'artdirector',
	ANIMATOR: 'animator',
	MODERATOR: 'moderator',
	PRACTITIONER: 'practitioner',
	VIDEOGRAPHER: 'videographer',
	ILLUSTRATOR: 'illustrator',
	ADMIN: 'admin',
	WRITER: 'writer'
};

export const isCompleteOrPending = status => isComplete(status) || isPending(status);
export const isComplete = status => status && API_STATUS.COMPLETED === status.toLowerCase();
export const isClear = status => status && API_STATUS.CLEAR === status.toLowerCase();
export const isPending = status => status && API_STATUS.PENDING === status.toLowerCase();
export const isRejected = status => status && API_STATUS.REJECTED === status.toLowerCase();
export const isLoading = status => status && API_STATUS.LOADING === status.toLowerCase();

// Event Status
export const EVENT_STATUS = {
	DRAFT: 'draft',
	SCHEDULED: 'scheduled',
	CANCELLED: 'cancelled',
	COMPLETED: 'completed'
};

export const EVENT_STATUS_LIST = [EVENT_STATUS.SCHEDULED, EVENT_STATUS.CANCELLED, EVENT_STATUS.COMPLETED];

export const isEventScheduled = status => status && EVENT_STATUS.SCHEDULED === status.toLowerCase();
export const isEventCompleted = status => status && EVENT_STATUS.COMPLETED === status.toLowerCase();
export const isEventCancelled = status => status && EVENT_STATUS.CANCELLED === status.toLowerCase();
export const isEventDrafted = status => status && EVENT_STATUS.DRAFT === status.toLowerCase();

// User Event Status
export const USER_EVENT_STATUS = {
	REGISTERED: 'registered',
	CANCELLED: 'cancelled',
	WAITLISTED: 'waitlisted',
	COMPLETED: 'completed',
	NOSHOW: 'noshow',
	PREMIUM: 'premium'
};

export const hasUserRegisteredToEvent = status => status && USER_EVENT_STATUS.REGISTERED === status.toLowerCase();
export const isUserEventCancelled = status => status && EVENT_STATUS.CANCELLED === status.toLowerCase();
export const isUserEventWaitlisted = status => status && EVENT_STATUS.WAITLISTED === status.toLowerCase();
export const isUserEventCompleted = status => status && EVENT_STATUS.COMPLETED === status.toLowerCase();
export const isUserEventNoShow = status => status && EVENT_STATUS.NOSHOW === status.toLowerCase();

export const EVENT_LEVEL = {
	L100: 'L100',
	L200: 'L200',
	L300: 'L300',
	L400: 'L400'
};
export const EVENT_LEVELS = [EVENT_LEVEL.L100, EVENT_LEVEL.L200, EVENT_LEVEL.L300, EVENT_LEVEL.L400];
export const isEventOpenToPublic = level => level && level.toLowerCase() === EVENT_LEVEL.L100.toLowerCase();

export const CATEGORY_LIST = ['Workshop', 'Round Table', '1 x 1 - Counsel / Therapy sessions'];
