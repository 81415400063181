import axios from 'axios';
// REACT_APP_YOUTUBE_API_KEY
// const GOOGLE_API_KEY = 'AIzaSyDUXy_0yIAYRO5SDaZcWiZnJMgQryXPi6E'; // firebase generated
// const GOOGLE_API_KEY = 'AIzaSyDfyTJeqiUUUXQi0wwTPMkgos9cDzxAqRI'; // only youtube api key
const GOOGLE_API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY; // only youtube api key

// const CHANNELID = 'UC181EuuUou8HFuDRMi-G3Ww';
const PLAYLISTID = {
	PARENTING: { playlistId: 'PLOEf1h2CrvDtP9eudfchzya9V1hG_MmFb', type: 'parents' },
	COUPLES: { playlistId: 'PLOEf1h2CrvDvycITJCDZrhfUb279d6QPV', type: 'couples' }
};
const MAX_PAGE_LIMIT = 50;
const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3';

const googleApiRequest = axios.create({
	baseURL: YOUTUBE_PLAYLIST_ITEMS_API,
	params: {
		key: GOOGLE_API_KEY
	}
});

export const fetchAllPlayListsVideos = async () => {
	const parentVideos = await getPlayListItems(PLAYLISTID.PARENTING);
	const coupleVideos = await getPlayListItems(PLAYLISTID.COUPLES);

	const allVideos = [...parentVideos, ...coupleVideos];
	const videoIds = allVideos.map(v => v.videoId);
	const allVideoDetails = await getAllPlaylistVideoDetails(videoIds);

	return allVideos.map(video => {
		const {
			snippet: { tags },
			statistics: { viewCount }
		} = allVideoDetails.find(vd => vd.id === video.videoId);

		return {
			...video,
			tags,
			viewCount: +viewCount
		};
	});
};

export const getPlayListItems = async (playlist = PLAYLISTID.PARENTING) => {
	const result = await googleApiRequest(`/playlistItems`, {
		params: {
			part: 'snippet,contentDetails,status',
			maxResults: MAX_PAGE_LIMIT,
			playlistId: playlist.playlistId,
			fields: `items(id,snippet/title,snippet/description,snippet/playlistId,snippet/publishedAt,snippet/thumbnails,contentDetails/videoId,contentDetails/videoPublishedAt,status/privacyStatus)`
		}
	});

	const publicVideos = result.data.items.filter(item =>
		['public', 'unlisted'].includes(item.status.privacyStatus.toLowerCase())
	);
	return publicVideos.map(video => {
		const {
			id,
			snippet: { title, description, playlistId, publishedAt, thumbnails },
			contentDetails: { videoId, videoPublishedAt: videoUploadedAt },
			status: { privacyStatus }
		} = video;
		return {
			id,
			videoId,
			title,
			description,
			playlistId,
			publishedAt,
			videoUploadedAt,
			thumbnails,
			privacyStatus,
			type: playlist.type
		};
	});
};

export const getAllPlaylistVideoDetails = async (videoIds = []) => {
	const details = await googleApiRequest(`/videos`, {
		params: {
			part: 'snippet,statistics',
			id: videoIds.join(','),
			fields: `items(id,snippet/tags,statistics/viewCount)`
		}
	});

	return details.data.items;
};
