import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from 'slices/constants';
import { fetchAllPlayListsVideos } from './libraryAPI';

let initialState = { loaded: false, searchText: '', filterType: 'all', videos: [] };

// Manage cache
let cachedLibrary = localStorage.getItem('r_library');
cachedLibrary = cachedLibrary && JSON.parse(cachedLibrary);
if (cachedLibrary) {
	initialState.loaded = true;
	initialState.videos = [...cachedLibrary];
}

// thunk & service
export const getVideosAsync = createAsyncThunk('library/fetchAllPlayListsVideos', async () => {
	const videosData = await fetchAllPlayListsVideos();
	return videosData?.length ? videosData : [];
});

export const librarySlice = createSlice({
	name: 'library',
	initialState,
	reducers: {
		setLibrary: (state, action) => {
			// TODO: setter
		}
	},

	extraReducers: builder => {
		builder
			.addCase(getVideosAsync.pending, state => {
				state.status = API_STATUS.PENDING;
			})
			.addCase(getVideosAsync.rejected, state => {
				state.status = API_STATUS.REJECTED;
			})
			.addCase(getVideosAsync.fulfilled, (state, action) => {
				localStorage.setItem('r_library', JSON.stringify(action.payload));
				state.loaded = true;
				state.videos = [...action.payload];
				state.status = API_STATUS.COMPLETED;
			});
	}
});

export const selectLibraryVideoById = videoId => state =>
	state.library.videos?.find(video => video.videoId === videoId);
export const selectLibraryVideos = state => state.library.videos;
export const selectHasLibaryVideosLoaded = state => state.library.loaded;

export const { setLibrary } = librarySlice.actions;

export default librarySlice.reducer;
