import { size as _size } from 'lodash-es';
import { dbUserEvents } from 'server/database/collections';
import { USER_EVENT_STATUS } from 'slices/constants';

export const hasUserAlreadyRegisteredToEvent = async eventId => {
	const exists = await dbUserEvents.getUserEvent(eventId);
	return !!exists;
};

export const fetchUserEvents = async () => {
	const userRegisteredEvents = await dbUserEvents.getUserEvents();
	return !_size(userRegisteredEvents) ? [] : userRegisteredEvents;
};

export const registerUserToEvent = async eventId => {
	const userRegisteredEvent = await dbUserEvents.registerUserToEvent(eventId, {
		status: USER_EVENT_STATUS.REGISTERED,
		registeredDate: new Date().toUTCString()
	});
	return !_size(userRegisteredEvent) ? [] : userRegisteredEvent;
};

export const unregisterUserToEvent = async eventId => {
	const unregisteredUserEvent = await dbUserEvents.updateUserEvent(eventId, {
		status: USER_EVENT_STATUS.CANCELLED,
		cancelledDate: new Date().toUTCString()
	});
	return !_size(unregisteredUserEvent) ? [] : unregisteredUserEvent;
};

export const addUserEventFeedback = async (eventId, feedback) => {
	const userEventFeedback = await dbUserEvents.updateUserEventFeedback(eventId, {
		...feedback,
		feedbackDate: new Date().toUTCString()
	});
	return !_size(userEventFeedback) ? {} : userEventFeedback;
};
