import React from 'react';
import { Box, Typography } from '@mui/material';

import Canvas from 'components/home/canvas';
import Footer from 'components/footer/footer';

import BrandStory from './brandStory';
import Wellness from './brandWellness';
import BrandImage from './brandImage';
import useStyles from './homeStyles';

const HomePage = () => {
	const classes = useStyles();

	return (
		<Box>
			<Typography component='div' className={classes.largeHeader}>
				<Canvas />
				<Typography className={classes.mainTitle}>
					Stories to connect, learn and heal.
				</Typography>
			</Typography>

			<BrandStory />
			<Wellness />
			<BrandImage />
			<Footer />
		</Box>
	);
};

export default HomePage;
