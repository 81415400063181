import { collection, doc, setDoc, addDoc, getDoc, updateDoc, deleteDoc, getDocs } from 'firebase/firestore';
import { dbFirestore } from '../appFirebase';

// Follow thw steps below to write data to firebase collection
// const demographics = [{ step: 1, question: 'Do you drink mimimum 4 liters of water daily?', options: ['Yes', 'No'] }];
// import { writeDataCollectionToDb } from 'database/db';
// import { demographics } from 'staticData';
// writeDataCollectionToDb(demographics, 'demographics');

export const writeDataCollectionToDb = (arrData, collectionName) =>
	arrData.forEach(obj => {
		addDoc(collection(dbFirestore, collectionName), obj);
	});

// collections
export const addDocToCollection = (collectionName, docObj) => addDoc(collection(dbFirestore, collectionName), docObj);

export const setDocToCollection = (id, collectionName, docObj) => {
	setDoc(doc(dbFirestore, collectionName, id), docObj);
};

export const setDocToColMerge = (id, collectionName, docObj) =>
	setDoc(doc(dbFirestore, collectionName, id), docObj, { merge: true });

export const updateDocToCollection = (id, collectionName, docObj) =>
	updateDoc(doc(dbFirestore, collectionName, id), docObj);

export const deleteDocFromCollection = (id, collectionName) => deleteDoc(doc(dbFirestore, collectionName, id));

export const getDocFromCollection = async path => {
	const dataRef = doc(dbFirestore, path);
	const docSnap = await getDoc(dataRef);
	return docSnap.exists() ? docSnap.data() : null;
};

export const getDocsFromCollection = async collectionName => {
	const querySnapshot = await getDocs(collection(dbFirestore, collectionName));
	const data = [];
	querySnapshot.forEach(item => {
		data.push({ id: item.id, ...item.data() });
	});
	return data;
};

// Sub collections
// export const getDocsFromSubCollection = (path, docObj) => {
// 	getDocs(doc(dbFirestore, path), docObj);
// };

export const getDocFromSubCollection = path => getDoc(doc(dbFirestore, path));
export const setDocToSubCollection = (path, docObj) => setDoc(doc(dbFirestore, path), docObj);
export const updateDocToSubCollection = (path, docObj) => updateDoc(doc(dbFirestore, path), docObj);

// query docs
export const getQueryData = async (query, keyField = 'gid') => {
	let data = [];
	const querySnapshot = await getDocs(query);
	if (!querySnapshot.size) return [];

	querySnapshot.forEach(doc => {
		data.push({ [keyField]: doc.id, ...doc.data() });
	});
	return data;
};
