import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import Friends from 'assets/images/brand/friends.jpeg';

const useStyles = makeStyles(theme => ({
	heroContainer: {
		backgroundImage: `url(${Friends})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		minHeight: '100vh',
		[theme.breakpoints.down('md')]: {
			minHeight: '45.4vh'
		}
	},
	overlay: {
		backgroundColor: theme.palette.primary.light
	}
}));

const BrandImage = () => {
	const classes = useStyles();

	return (
		<Box className={classes.overlay}>
			<Box className={classes.heroContainer} />
		</Box>
	);
};

export default BrandImage;
