import React from 'react';
import { Container, Box, Typography, Grid } from '@mui/material';
import Copyright from 'components/common/copyright';
import footerStyles from './footerStyles';

const Footer = () => {
	const classes = footerStyles();

	return (
		<Box className={classes.footerContainer} pt={7} pb={6}>
			<Container maxWidth='lg'>
				<Grid container>
					<Grid item xs={12} md={6}>
						<Box>
							<Typography variant='h5' color='textSecondary' style={{ fontWeight: '600' }}>
								Rawyy Inc.
							</Typography>
						</Box>
						<Box my={4}>
							<Typography className={classes.address} variant='body1' color='textSecondary'>
								123 Rawi Street
							</Typography>
							<Typography className={classes.address} variant='body1' color='textSecondary'>
								Chicago, IL 99999
							</Typography>
							<Typography className={classes.address} variant='body1' color='textSecondary'>
								(999) 999-9999
							</Typography>
						</Box>
					</Grid>
				</Grid>
				<Copyright />
			</Container>
		</Box>
	);
};

export default Footer;
