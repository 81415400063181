import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

const AuthGuard = ({ children }) => {
	const { isLoggedIn } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isLoggedIn) {
			navigate('/auth/login', { replace: true });
		}
	}, [isLoggedIn, navigate]);

	return children;
};

AuthGuard.propTypes = {
	children: PropTypes.node
};

export default AuthGuard;
