import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'routes/sidebar';
import { selectIsAdmin } from 'slices/user/userSlice';

const MenuList = () => {
	const isAdmin = useSelector(selectIsAdmin);

	const sidebarItems = isAdmin ? menuItem.items : menuItem.items.filter(sb => !sb.admin);
	const navItems = sidebarItems.map(item => {
		switch (item.type) {
			case 'group':
				return <NavGroup key={item.id} item={item} />;
			default:
				return (
					<Typography key={item.id} variant='h6' color='error' align='center'>
						Menu Items Error
					</Typography>
				);
		}
	});

	return navItems;
};

export default MenuList;
