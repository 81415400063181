import React from 'react';
import { Outlet } from 'react-router-dom';

// project imports
import Feedback from 'layout/Feedback';

const MinimalLayout = () => (
	<>
		<Outlet />
		<Feedback />
	</>
);

export default MinimalLayout;
