import { dbEvents, dbFeedback } from 'server/database/collections';
import { size as _size } from 'lodash-es';
import { EVENT_STATUS } from 'slices/constants';

export const mapEventModel = event => {
	const date = new Date(event.eventDateTime);

	return {
		...event,
		startedAt: date
		// finishedAt: addMinutes(date.getTime(), event.durationMins)
	};
};

export const fetchEvents = async () => {
	const events = await dbEvents.getEvents();
	return !_size(events) ? [] : events;
};

export const addEvent = async newEvent => {
	newEvent.status = EVENT_STATUS.SCHEDULED;
	const addedEvent = await dbEvents.addEvent(newEvent);
	return addedEvent;
};

export const updateEvent = async (eventId, event) => {
	event.modifiedDate = new Date().toUTCString();
	const updatedEvent = await dbEvents.updateEvent(eventId, event);
	return updatedEvent;
};

export const fetchEventDetails = async eventId => {
	const eventDetails = await dbEvents.getEventDetails(eventId);
	return eventDetails || {};
};

export const fetchEventFeedbackQuestions = async () => {
	const feedbackQuestions = await dbFeedback.getEventFeedbackQuestions();
	return feedbackQuestions || {};
};
