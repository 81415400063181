import { useRoutes } from 'react-router-dom';

// routes
import HomeRoutes from './route-definitions/HomeRoutes';
import LoginRoutes from './route-definitions/LoginRoutes';
import MainRoutes from './route-definitions/MainRoutes';
import AdminRoutes from './route-definitions/AdminRoutes';
import LibraryRoutes from './route-definitions/LibraryRoutes';
import PublicRoutes from './route-definitions/PublicRoutes';
import DemographicsRoute from './route-definitions/DemographicsRoute';

export default function ThemeRoutes() {
	return useRoutes([
		HomeRoutes,
		PublicRoutes,
		LoginRoutes,
		DemographicsRoute,
		MainRoutes,
		AdminRoutes,
		LibraryRoutes
	]);
}
