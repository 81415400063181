import { updateDocToSubCollection, setDocToSubCollection, getDocFromCollection } from './dbUtils';

// get Guest User Event
export const getGuestUserEvent = (guestId, eventId) => {
	return getDocFromCollection(`guests/${guestId}/events/${eventId}`);
};

export const registerGuestUserEvent = async (guestId, eventId, eventData) => {
	setDocToSubCollection(`guests/${guestId}/events/${eventId}`, { guestId, eventId, ...eventData });
	return { guestId, eventId, ...eventData };
};

export const updateGuestUserEvent = async (guestId, eventId, eventData) => {
	updateDocToSubCollection(`guests/${guestId}/events/${eventId}`, { guestId, eventId, ...eventData });
	return { guestId, eventId, ...eventData };
};

export const updateGuestUserEventFeedback = async (guestId, eventId, feedback) => {
	updateDocToSubCollection(`guests/${guestId}/events/${eventId}`, { guestId, eventId, feedback });
	return { guestId, eventId, feedback };
};