import PropTypes from 'prop-types';
import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// third-party
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signOut,
	signInWithPopup,
	GoogleAuthProvider,
	FacebookAuthProvider
} from 'firebase/auth';

// action - state management
import { setUser, selectUser, createUserAsync, getUserInfoAsync } from 'slices/user/userSlice';

// project imports
import Loader from 'components/common/Loader';
import { authFire } from 'server/database/appFirebase';
import { mapLoggedUserInfo } from './firebaseContextUtil';

const initialState = {
	isLoggedIn: false,
	isInitialized: false,
	user: null
};
const FirebaseContext = createContext({
	...initialState,
	doCreateUserWithEmailAndPassword: () => Promise.resolve(),
	doSignInWithEmailAndPassword: () => Promise.resolve(),
	doLoginWithGoogle: () => Promise.resolve(),
	doLoginWithFacebook: () => Promise.resolve(),
	doLogout: () => Promise.resolve()
});

export const FirebaseProvider = ({ children }) => {
	const dispatch = useDispatch();
	const user = useSelector(selectUser);

	const manageLoggedUserInfo = async (loggedUser, signup) => {
		const mappedUser = mapLoggedUserInfo(loggedUser.user);
		if (signup) {
			dispatch(createUserAsync(mappedUser));
		} else {
			let loggedUser = await dispatch(getUserInfoAsync(mappedUser.uid)).unwrap();
			if (!loggedUser) {
				loggedUser = await dispatch(createUserAsync(mappedUser)).unwrap();
			}

			dispatch(setUser(loggedUser));
		}
		return loggedUser;
	};

	const doCreateUserWithEmailAndPassword = async userInfo => {
		const { email, password, fullName, displayName } = userInfo;

		const loggedUser = await createUserWithEmailAndPassword(authFire, email, password);
		await manageLoggedUserInfo({ ...loggedUser, user: { ...loggedUser.user, fullName, displayName } }, true);
	};

	const doSignInWithEmailAndPassword = async ({ email, password }) => {
		const loggedUser = await signInWithEmailAndPassword(authFire, email, password);
		await manageLoggedUserInfo(loggedUser);
	};

	const doLoginWithGoogle = async (signup = false) => {
		const loggedUser = await signInWithPopup(authFire, new GoogleAuthProvider());
		await manageLoggedUserInfo(loggedUser, signup);
	};

	const doLoginWithFacebook = () => {
		const provider = new FacebookAuthProvider();
		provider.setCustomParameters({
			display: 'popup'
		});
		return signInWithPopup(authFire, provider);
	};

	const doLogout = () => signOut(authFire);

	useEffect(() => {
		const unsubscribe = authFire.onAuthStateChanged(async fsUser => {
			if (!fsUser) {
				dispatch(
					setUser({
						isLoggedIn: false,
						user: null
					})
				);
			}
		});
		return unsubscribe;
	}, [dispatch]);

	if (!user.isInitialized) {
		return <Loader />;
	}

	return (
		<FirebaseContext.Provider
			value={{
				isLoggedIn: user.isLoggedIn,
				userId: user.info?.userId,
				doCreateUserWithEmailAndPassword,
				doSignInWithEmailAndPassword,
				doLoginWithGoogle,
				doLoginWithFacebook,
				doLogout
			}}>
			{children}
		</FirebaseContext.Provider>
	);
};

FirebaseProvider.propTypes = {
	children: PropTypes.node
};

export default FirebaseContext;
