import React, { lazy } from 'react';

// project imports
import AuthGuard from 'routes/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/common/Loadable';

// page components
const LibrariesPage = Loadable(lazy(() => import('views/pages/libraries/librariesPage')));
const LibraryViewPage = Loadable(lazy(() => import('views/pages/libraries/libraryViewPage')));

const LibraryRoutes = {
	path: '/library',
	element: (
		<AuthGuard>
			<MainLayout />
		</AuthGuard>
	),
	children: [
		{
			path: '/library/all',
			element: <LibrariesPage />
		},
		{
			path: '/library/video/:videoId',
			element: <LibraryViewPage />
		}
	]
};

export default LibraryRoutes;
