import { size as _size } from 'lodash-es';
import { dbGuestEvents, dbGuest } from 'server/database/collections';
import { USER_EVENT_STATUS } from 'slices/constants';

export const fetchGuestDetails = async (email, phoneNumber) => {
	const guestUser = await dbGuest.findGuestUser(email, phoneNumber);
	return !_size(guestUser) ? [] : guestUser;
};

export const addGuestUser = async guestInfo => {
	const guestUser = await dbGuest.doCreateGuestUser(guestInfo);
	return !_size(guestUser) ? [] : guestUser;
};

export const updateGuestUser =  (guestId, guestInfo) => {
	return dbGuest.doUpdateGuestUser(guestId, guestInfo);
};

export const guestAlreadyRegisteredToEvent = async (guestId, eventId) => {
	const guestEventDetails = await dbGuestEvents.getGuestUserEvent(guestId, eventId);
	return guestEventDetails;
};

export const registerGuestUserToEvent = async (guestId, eventId, data) => {
	const guestRegisteredEvent = await dbGuestEvents.registerGuestUserEvent(guestId, eventId, {
		status: USER_EVENT_STATUS.REGISTERED,
		registeredDate: new Date().toUTCString()
	});
	return !_size(guestRegisteredEvent) ? [] : guestRegisteredEvent;
};

export const unregisterGuestUserToEvent = async (guestId, eventId, data) => {
	const unregisteredGuestEvent = await dbGuestEvents.registerGuestUserEvent(guestId, eventId, {
		status: USER_EVENT_STATUS.CANCELLED,
		cancelledDate: new Date().toUTCString()
	});
	return !_size(unregisteredGuestEvent) ? [] : unregisteredGuestEvent;
};

export const addGuestUserEventFeedback = async (guestId, eventId, feedback) => {
	const guestUserEventFeedback = await dbGuestEvents.updateGuestUserEventFeedback(guestId, eventId, {
		...feedback,
		feedbackDate: new Date().toUTCString()
	});
	return !_size(guestUserEventFeedback) ? [] : guestUserEventFeedback;
};
