import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import Logo from 'components/common/Logo';

const LogoSection = () => (
	<ButtonBase disableRipple component={Link} to='/'>
		<Logo />
	</ButtonBase>
);

export default LogoSection;
