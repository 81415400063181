import React from 'react';
import { Box, Typography, Container, Grid, CardMedia } from '@mui/material';
import { makeStyles } from '@mui/styles';

import wellnessIcon1 from 'assets/images/wellness/wellness4.jpeg';
import wellnessIcon2 from 'assets/images/wellness/wellness2.jpeg';
import wellnessIcon3 from 'assets/images/wellness/wellness3.jpeg';

const useStyles = makeStyles(theme => ({
	bg: {
		backgroundColor: theme.palette.primary.light,
		padding: '10px 0'
	},
	image: {
		cursor: 'pointer',
		width: 'auto',
		maxWidth: 350,
		maxHeight: 200,
		[theme.breakpoints.down('lg')]: {
			maxWidth: '100%',
			maxHeight: '100%'
		}
	}
}));

const Wellness = () => {
	const classes = useStyles();

	return (
		<Box className={classes.bg}>
			<Container maxWidth='lg'>
				<Box>
					<Box>
						<Typography variant='h4' style={{ fontWeight: 500 }} align='center'>
							Wellness
						</Typography>
					</Box>
					<Box mt={4}>
						<Typography variant='body1' style={{ fontWeight: 300 }} align='center'>
							Lorem ipsum dolor, sit amet consectetur adipisicing elit. Totam atque sunt, fugiat at quae
							beatae veniam eaque molestiae ratione nisi iste odit dolor iusto omnis quisquam, deleniti
							provident eligendi voluptatem.
						</Typography>
					</Box>
					<Box my={8}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4}>
								<CardMedia
									className={classes.image}
									component='img'
									src={wellnessIcon1}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<CardMedia
									className={classes.image}
									component='img'
									src={wellnessIcon2}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Box
									className={classes.image}
									component='img'
									src={wellnessIcon3}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default Wellness;
