import { makeStyles } from '@mui/styles';

const footerStyles = makeStyles(theme => ({
	footerContainer: {
		backgroundColor: 'black',
		color: '#fff'
	},
	iconContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		cursor: 'pointer',
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'flex-start'
		}
	},
	icon: {
		fill: '#ffffff'
	},
	address: {
		fontWeight: 300,
		lineHeight: 1.75
	}
}));

export default footerStyles;
