import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import config from 'config';
import useQuery from 'hooks/useQuery';

const GuestGuard = ({ children }) => {
	const { isLoggedIn } = useAuth();
	const navigate = useNavigate();
	// const query = useQuery();
	// const isPlainRoute = !query.get('eid') && !query.get('toPage');
	const query = useQuery();
	const eid = query.get('eid');
	const toPage = query.get('to');

	useEffect(() => {
		if (isLoggedIn) {
			// navigate(config.defaultPath, { replace: true });
			if (toPage) {
				navigate(`/${toPage}`, { replace: true });
			} else if (eid) {
				navigate(`/event/registration/${eid}`, { replace: true });
			} else {
				navigate(config.defaultPath, { replace: true });
			}
		}
		
	}, [isLoggedIn, navigate]);

	return children;
};

GuestGuard.propTypes = {
	children: PropTypes.node
};

export default GuestGuard;
