import React from 'react';
import { Outlet } from 'react-router-dom';

// project imports
import Feedback from 'layout/Feedback';
import { makeStyles } from '@mui/styles';
import { AppBar as MuiAppBar, CssBaseline, Toolbar } from '@mui/material';
import Header from '../Header';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex'
	},
	appBar: {
		backgroundColor: theme.palette.background.default
	},
	boxContainer: {
		width: '228px',
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			width: 'auto'
		}
	},
	content: {
		...theme.typography.mainContent,
		marginRight: 0
	}
}));

const PublicLayout = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CssBaseline />
			{/* header */}
			<MuiAppBar enableColorOnDark position='fixed' color='inherit' elevation={0} className={classes.appBar}>
				<Toolbar>
					<Header />
				</Toolbar>
			</MuiAppBar>

			{/* main content */}
			<main className={classes.content}>
				<Outlet />
			</main>
			<Feedback />
		</div>
	);
};

export default PublicLayout;
