import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { makeStyles } from '@mui/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileMenu from './ProfileSection/ProfileMenu';

// assets
import { IconMenu2 } from '@tabler/icons';

// style constant
const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1
	},
	headerAvatar: {
		...theme.typography.commonAvatar,
		...theme.typography.mediumAvatar,
		transition: 'all .2s ease-in-out',
		background: theme.palette.primary.light,
		color: theme.palette.primary.dark,
		'&:hover': {
			background: theme.palette.primary.dark,
			color: theme.palette.primary.light
		}
	},
	boxContainer: {
		width: '228px',
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			width: 'auto'
		}
	}
}));

const Header = ({ handleLeftDrawerToggle }) => {
    const classes = useStyles();

    return (
        <>
            {/* logo & toggler button */}
            <div className={classes.boxContainer}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar variant="rounded" className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color="inherit">
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </div>

            {/* header search */}
            {/* <SearchSection theme="light" /> */}
            <div className={classes.grow} />
            <div className={classes.grow} />

            {/* notification & profile */}
            <ProfileMenu />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
