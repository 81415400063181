import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { orderBy as _orderBy, sortedUniq as _sortedUniq } from 'lodash-es';
import { API_STATUS } from 'slices/constants';
import { fetchEventDetails } from './eventRegistrationAPI';

const initialState = {
	eventLoaded: false,
	status: '',
	eventDetails: null
};

// thunk & service
export const getEventDetailsAsync = createAsyncThunk('eventRegistration/fetchEventDetails', async eventId => {
	const eventData = await fetchEventDetails(eventId);
	return { id: eventId, ...eventData };
});

export const eventRegistrationSlice = createSlice({
	name: 'eventRegistration',
	initialState,
	reducers: {
		setEventDetails: (state, action) => {
			// TODO
		}
	},

	extraReducers: builder => {
		builder
			.addCase(getEventDetailsAsync.pending, state => {
				state.status = API_STATUS.PENDING;
			})
			.addCase(getEventDetailsAsync.rejected, state => {
				state.status = API_STATUS.REJECTED;
			})
			.addCase(getEventDetailsAsync.fulfilled, (state, action) => {
				state.eventDetails = action.payload;
				state.eventLoaded = true;
				state.status = API_STATUS.COMPLETED;
			});
	}
});

export const selectEventLoaded = state => state.eventRegistration.status !== API_STATUS.PENDING;
export const selectEventDetails = eventId => state => {
	if (eventId === state.eventRegistration.eventDetails?.id) return state.eventRegistration.eventDetails;
};

export const { setEventDetails } = eventRegistrationSlice.actions;

export default eventRegistrationSlice.reducer;
