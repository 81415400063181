import { size as _size } from 'lodash-es';
import { addDocToCollection, getDocsFromCollection } from './dbUtils';

export const addAppFeedback = async feedback => {
	const dbFeedback = await addDocToCollection(`appFeedback`, feedback);
	return { id: dbFeedback.id, ...feedback };
};

export const getEventFeedbackQuestions = async () => {
	const feedbackQuestions = await getDocsFromCollection('eventFeedbacks');
	return _size(feedbackQuestions) > 0 ? feedbackQuestions[0] : [];
};
