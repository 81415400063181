import { collection, getDocs } from 'firebase/firestore';
import { dbFirestore } from '../appFirebase';


export const getAudience = async () => {
	const querySnapshot = await getDocs(collection(dbFirestore, 'audience'));
	const audience = [];
	querySnapshot.forEach(aud => {
		audience.push({ id: aud.id, ...aud.data() });
	});
	return audience;
};

