import { doc, collection, getDocs, getDoc } from 'firebase/firestore';
import { authFire } from '../appFirebase';
import { dbFirestore } from '../appFirebase';
import { addDocToCollection, setDocToCollection, updateDocToCollection } from './dbUtils';

// get demographics data
// doc.data() is never undefined for query doc snapshots
// console.log(demographic.id, ' => ', demographic.data());
// Manage Events
export const getEvents = async () => {
	const querySnapshot = await getDocs(collection(dbFirestore, 'events'));
	const events = [];
	querySnapshot.forEach(event => {
		events.push({ id: event.id, ...event.data() });
	});
	return events;
};

export const getEventDetails = eventId => {
	const eventRef = doc(dbFirestore, 'events', eventId);
	return getDoc(eventRef).then(docSnap => {
		if (docSnap.exists()) {
			return docSnap.data();
		}
	});
};

export const addEvent = async newEvent => {
	const event = await addDocToCollection('events', { ...newEvent });
	return { id: event.id, ...newEvent };
};

export const updateEvent = (eventId, updatedEvent) => {
	updateDocToCollection(eventId, 'events', updatedEvent);
	return { id: eventId, ...updatedEvent };
};
