import React, { lazy } from 'react';

// project imports
import AuthGuard from 'routes/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/common/Loadable';

// Pages
const SamplePage = Loadable(lazy(() => import('views/pages/samplePage')));
const EventRegistrationPage = Loadable(lazy(() => import('views/pages/events/eventRegistrationPage')));
const EventFeedbackPage = Loadable(lazy(() => import('views/pages/events/eventFeedbackPage')));
const ProfilePage = Loadable(lazy(() => import('views/pages/profile/profilePage')));
const PreferencesPage = Loadable(lazy(() => import('views/pages/preferences/preferencesPage')));
const AskTheExpertPage = Loadable(lazy(() => import('views/pages/askTheExpert/askTheExpertPage')));

const MainRoutes = {
	path: '/',
	element: (
		<AuthGuard>
			<MainLayout />
		</AuthGuard>
	),
	children: [
		{
			path: '/assessments',
			element: <SamplePage title='Assessments' />
		},
		{
			path: '/event/registration/:eventId',
			element: <EventRegistrationPage />
		},
		{
			path: '/event/feedback/:eventId',
			element: <EventFeedbackPage />
		},

		{
			path: '/event/registration',
			element: <SamplePage title='User Events' />
		},
		{
			path: '/askTheExpert',
			element: <AskTheExpertPage />
		},
		{
			path: '/profile',
			element: <ProfilePage />
		},
		{
			path: '/preferences',
			element: <PreferencesPage />
		}
	]
};

export default MainRoutes;
