import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import Main from 'assets/images/brand/main.jpeg';

const useStyles = makeStyles(theme => ({
	heroContainer: {
		backgroundImage: `url(${Main})`,
		opacity: 1,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		[theme.breakpoints.down('md')]: {
			backgroundPosition: 'center'
		},
		maxHeight: '92.4vh'
	},
	overlay: {
		backgroundColor: 'rgba(223,124,109,.2)',
		minHeight: '92.4vh',
		[theme.breakpoints.down('md')]: {
			minHeight: '45.4vh'
		}
	}
}));

const BrandStory = () => {
	const classes = useStyles();

	return (
		<Box>
			<Box className={classes.heroContainer}>
				<Box className={classes.overlay} />
			</Box>
		</Box>
	);
};

export default BrandStory;
