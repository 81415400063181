import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { API_STATUS } from 'slices/constants';
import { fetchEvents, addEvent, updateEvent, fetchEventDetails, fetchEventFeedbackQuestions } from './eventsAPI';

const initialState = {
	status: API_STATUS.CLEAR,
	eventsList: []
};

export const getEventsAsync = createAsyncThunk('events/fetchEvents', async () => {
	const events = await fetchEvents();
	return { events };
});

export const getEventDetailsAsync = createAsyncThunk('events/fetchEventDetails', async eventId => {
	const event = await fetchEventDetails(eventId);
	return { event };
});

export const addEventAsync = createAsyncThunk('events/addEvent', async newEvent => {
	const event = await addEvent(newEvent);
	return { event };
});

export const updateEventAsync = createAsyncThunk('events/updateEvent', async payload => {
	const { eventId, event: updatedEvent } = payload;
	const event = await updateEvent(eventId, updatedEvent);
	return { event };
});

export const fetchEventFeedbackQuestionsAsync = createAsyncThunk('events/fetchEventFeedbackQuestions', async () => {
	const feedbackQuestions = await fetchEventFeedbackQuestions();
	return { feedbackQuestions };
});

const eventsSlice = createSlice({
	name: 'events',
	initialState,
	reducers: {
		setEvents: (state, action) => {
			state.events = action.payload.events;
			state.loaded = true;
		}
	},

	extraReducers: builder => {
		builder
			.addCase(getEventsAsync.fulfilled, (state, action) => {
				state.eventsList = action.payload.events;
				state.status = API_STATUS.COMPLETED;
				state.loaded = true;
			})
			.addCase(getEventsAsync.rejected, state => {
				state.status = API_STATUS.REJECTED;
			})
			.addCase(addEventAsync.fulfilled, (state, action) => {
				state.eventsList.push(action.payload.event);
				state.eventAdded = API_STATUS.COMPLETED;
			})
			.addCase(addEventAsync.rejected, state => {
				state.eventAdded = API_STATUS.REJECTED;
			})
			.addCase(updateEventAsync.fulfilled, (state, action) => {
				state.eventsList = state.eventsList.map(event => {
					if (event.id !== action.payload.event.id) {
						return event;
					}

					return Object.assign({}, event, {
						...action.payload.event
					});
				});

				// console.log(current(state));
			})
			.addCase(updateEventAsync.rejected, state => {
				state.eventUpdated = API_STATUS.REJECTED;
			})
			.addCase(getEventDetailsAsync.fulfilled, (state, action) => {
				if (state.eventsList.some(event => event.id !== action.payload.event.id)) {
					state.eventsList.push(action.payload.event);
				}
				state.fetchEvent = API_STATUS.COMPLETED;
			})
			.addCase(getEventDetailsAsync.rejected, state => {
				state.fetchEvent = API_STATUS.REJECTED;
			})
			.addCase(fetchEventFeedbackQuestionsAsync.fulfilled, (state, action) => {
				state.feedbackQuestions = action.payload.feedbackQuestions;
				state.feedbackQuestionsStatus = API_STATUS.COMPLETED;
			})
			.addCase(fetchEventFeedbackQuestionsAsync.rejected, state => {
				state.feedbackQuestionsStatus = API_STATUS.REJECTED;
			});
	}
});

export const selectEventsLoaded = state => state.events.loaded;
export const selectEventsList = state => state.events.eventsList;
export const selectEventDetails = state => eventId => state.events.eventsList.find(e => e.id === eventId);
export const selectEventFeedbackQuestions = state => state.events.feedbackQuestions?.questions;

export const { setEvents } = eventsSlice.actions;

export default eventsSlice.reducer;
