import { format, addDays, endOfDay } from 'date-fns';

const dateFormat = 'MM-dd-yyyy';
export const getCurrentDate = () => {
	const today = new Date();
	return format(endOfDay(today), dateFormat);
};

export const addDaysToCurrentDate = (days = 30) => format(addDays(new Date(), days), dateFormat);

export const lastOneMonthList = (list, dateProp) => {
	var today = new Date();
	var last30DaysBackDate = today.setDate(today.getDate() - 30);
	var date30DaysBack = new Date(last30DaysBackDate);

	return list.filter(item => item[dateProp] >= date30DaysBack);
};

export const tomorrow = () => {
	const today = new Date();
	const nextDay = new Date(today);
	nextDay.setDate(nextDay.getDate() + 1);

	return nextDay;
};

export const timeFromNow = time => {
	let unixTime = new Date(time).getTime();
	if (!unixTime) return;
	let now = new Date().getTime();

	// Calculate difference
	let difference = unixTime / 1000 - now / 1000;
	return Math.floor((-1 * difference) / (60 * 60 * 24));
};
