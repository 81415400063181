import { makeStyles } from '@mui/styles';
import { red } from '@mui/material/colors';

const homeStyles = makeStyles(theme => ({
	root: {
		height: '93.5vh',
		width: '100',
		backgroundImage: `url(${'/images/main.jpeg'})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	},
	card: {
		maxWidth: 500
	},
	content: {
		height: '20%'
	},
	media: {
		height: 0,
		paddingTop: '56.25%' // 16:9
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	},
	avatar: {
		backgroundColor: red[500]
	},
	largeHeader: {
		position: 'relative',
		width: '100%',
		background: '#fff',
		overflow: 'hidden',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		zIndex: 1
	},
	mainTitle: {
		position: 'absolute',
		margin: 0,
		paddingTop: 90,
		color: '#000',
		textAlign: 'center',
		fontFamily: 'raleway',
		fontSize: '3.8em',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.4em'
		},
		fontWeight: '600',
		top: '20%',
		left: '50%',
		transform: `translate3d(-50%, -50%, 0)`
	}
}));

export default homeStyles;
