// assets
import { FormattedMessage } from 'react-intl';
import { IconBrandChrome, IconBrandYoutube, IconTicket } from '@tabler/icons';

const other = {
	id: 'others',
	type: 'group',
	children: [
		{
			id: 'registration',
			title: 'My Events',
			type: 'item',
			url: '/event/registration',
			icon: IconTicket,
			breadcrumbs: false
		},
		{
			id: 'libraryall',
			title: <FormattedMessage id='allMessage' defaultMessage='Content Library' />,
			type: 'item',
			icon: IconBrandYoutube,
			url: '/library/all',
			breadcrumbs: false
		},
		{
			id: 'assessments',
			title: 'Assessments',
			type: 'item',
			url: '/assessments',
			icon: IconBrandChrome,
			breadcrumbs: false
		}
	]
};

export default other;
