import { query, where, collection, limit } from 'firebase/firestore';
import { size as _size } from 'lodash-es';
import { dbFirestore } from '../appFirebase';
import { addDocToCollection, getQueryData, updateDocToCollection } from './dbUtils';

// create an user in and store it at users/id path
export const doCreateGuestUser = async newGuestUser => {
	const guest = await addDocToCollection('guests', { ...newGuestUser });
	await doUpdateGuestUser(guest.id, { gid: guest.id });

	return { gid: guest.id, ...newGuestUser };
};

export const doUpdateGuestUser = (guestId, data) => updateDocToCollection(guestId, 'guests', data);

// get User
export const findGuestUser = async (email, phoneNumber) => {
	const guestRef = collection(dbFirestore, 'guests');
	const emailQ = query(guestRef, where('email', '==', email.toLowerCase()), limit(1));
	const emailResults = await getQueryData(emailQ);
	if (_size(emailResults)) {
		return emailResults[0];
	}

	const phoneNumberQ = query(guestRef, where('phoneNumber', '==', phoneNumber), limit(1));
	const phoneResults = await getQueryData(phoneNumberQ);
	if (_size(phoneResults)) {
		return phoneResults[0];
	}
	return null;
};
