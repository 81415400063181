import { useCanvasContext } from 'hooks/useCanvas';
import useResponsiveSize from 'hooks/useResponsiveSize';
import { default as WaveObj } from '../lib/wave';

const Wave = () => {
    const { context } = useCanvasContext();
    const { width, height } = useResponsiveSize();
    let frequency = 0.013;
    const waves = {
		frontWave: new WaveObj([0.0112, 0.026, 0.014], 'rgba(100, 84, 172,0.88)'),
		backWave: new WaveObj([0.0124, 0.016, 0.004], 'rgba(56, 41, 37,0.48)')
	};

    const render = () => {
		context?.clearRect(0, 0, width, height);
		Object.entries(waves).forEach(([, wave]) => {
			wave.draw(context, width, height, frequency);
		});
		// frequency += 0.00125;
		requestAnimationFrame(render);
	};
    if (context) render();
    return null;
};

export default Wave;