import React from 'react';
import {
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Tooltip,
	Grid
} from '@mui/material';
import { noop } from 'lodash-es';
// import { useStoreState } from 'easy-peasy';

const email_regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const applicationAreas = [
	{ id: 'general', value: 'General' },
	{ id: 'assessments', value: 'Assessments' },
	{ id: 'events', value: 'Events' },
	{ id: 'profile', value: 'Profile' },
	{ id: 'library', value: 'Library' },
	{ id: 'others', value: 'Others' }
];

const AppFeedback = ({ onClose = noop }) => {
	// const userEmail = useStoreState(state => state.user.email);
	// const loggedIn = useStoreState(state => state.user.loggedIn);
	const userEmail = 'testers';
	const loggedIn = false;
	const [feedback, setFeedback] = React.useState({
		area: 'general',
		name: '',
		email: '',
		phoneNumber: '',
		description: ''
	});
	const isInternalUser = userEmail.endsWith('@rawyy.com');

	const updateFeeback = (prop, value) => {
		setFeedback(prev => ({ ...prev, [prop]: value }));
	};

	const handleAreaChange = ({ target }) => updateFeeback('area', target.value);
	const onDescriptionChange = ({ target }) => updateFeeback('description', target.value);
	const onEmailChange = ({ target }) => updateFeeback('email', target.value);
	const onPhoneChange = ({ target }) => updateFeeback('phoneNumber', target.value);
	const onNameChange = ({ target }) => updateFeeback('name', target.value);

	const hasRequiredFields = () =>
		isInternalUser
			? feedback.area && feedback.name && feedback.email && feedback.description
			: !!feedback.area && !!feedback.description;

	const handleOnSubmit = () => {
		if (hasRequiredFields()) onClose(feedback);
	};

	const handleOnClose = () => onClose();

	return (
		<Grid container>
			<Grid item xs={12}>
				<FormControl variant='standard' sx={{ my: 3, width: '100%' }}>
					<InputLabel id='feedback-application-areas-label'>Application Feature</InputLabel>
					<Select
						autoFocus
						margin='dense'
						labelId='feedback-application-areas-label'
						id='feedback-application-areas-select'
						value={feedback.area}
						label='Application Feature'
						required={isInternalUser}
						onChange={handleAreaChange}
						disabled={!loggedIn}>
						{applicationAreas.map(item => (
							<MenuItem key={item.id} value={item.id}>
								{item.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{!isInternalUser && (
					<>
						<TextField
							variant='standard'
							sx={{ mb: 2 }}
							value={feedback.name}
							onChange={onNameChange}
							margin='dense'
							id='feedback-application-name'
							InputLabelProps={{
								shrink: true
							}}
							label='Name'
							fullWidth
							required
						/>
						<TextField
							variant='standard'
							sx={{ mb: 2 }}
							value={feedback.email}
							onChange={onEmailChange}
							error={Boolean(feedback.email) && !email_regex.test(feedback.email)}
							helperText={
								feedback.email &&
								!email_regex.test(feedback.email) &&
								'Please enter a valid email address.'
							}
							margin='dense'
							id='feedback-application-email'
							InputLabelProps={{
								shrink: true
							}}
							label='Email Address'
							fullWidth
							required
						/>
						<TextField
							variant='standard'
							sx={{ mb: 2 }}
							value={feedback.phoneNumber}
							onChange={onPhoneChange}
							margin='dense'
							id='feedback-application-phone'
							InputLabelProps={{
								shrink: true
							}}
							label='Phone Number'
							fullWidth
						/>
					</>
				)}
				<TextField
					variant='standard'
					sx={{ overflowY: 'visible', height: 'auto' }}
					value={feedback.description}
					onChange={onDescriptionChange}
					margin='dense'
					id='feedback-application-description'
					InputLabelProps={{
						shrink: true
					}}
					label='Feedback Description'
					multiline
					required
					rows={4}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12} alignItems='center' justifyContent='space-between'>
				<Button onClick={handleOnClose} className='cancel-button'>
					Cancel
				</Button>
				<Tooltip
					arrow
					title='Submit feedback'
					placement='top'
					disableFocusListener={!!feedback.description}
					disableHoverListener={!!feedback.description}
					disableTouchListener={!!feedback.description}>
					<Button
						id='feedback-application-submit'
						onClick={handleOnSubmit}
						disabled={!hasRequiredFields()}
						component='div'
						className='submit-button'>
						Submit
					</Button>
				</Tooltip>
			</Grid>
		</Grid>
	);
};

export default AppFeedback;
