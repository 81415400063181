import { size as _size } from 'lodash-es';

export const mapLoggedUserInfo = user => {
	const { providerData, uid, email, fullName, displayName, photoURL, phoneNumber, emailVerified = false } = user;
	const providerId = providerData[0].providerId === 'password' ? 'email' : providerData[0].providerId;

	const loggedUser = {
		uid,
		email,
		fullName: fullName || displayName,
		displayName: displayName || fullName,
		photoURL,
		phoneNumber,
		emailVerified,
		providerId
	};

	// if (providerData && _size(providerData) > 0)
	// 	switch (providerData[0].providerId) {
	// 		case 'google.com':
	// 			const provider = user.providerData[0];
	// 			const googleUser = user.providerData[0];
	// 			return { uid };
	// 		case 'facebook.com':
	// 			const fbUser = user.providerData[0];
	// 			return fbUser;
	// 		case 'firebase':
	// 			const manualUser = user.providerData[0];
	// 			return manualUser;
	// 		default:
	// 			return {};
	// 	}
	return loggedUser;
};
