import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import { AppBar, CssBaseline, Toolbar, useMediaQuery } from "@mui/material";

// third-party
import clsx from "clsx";

// project imports
import Breadcrumbs from "components/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Feedback from 'layout/Feedback';
import navigation from "routes/sidebar";
import { drawerWidth } from "utils/constant";
import { setMenu, selectMenu } from "slices/customization/customizationSlice";

// assets
import { IconChevronRight } from "@tabler/icons";

// style constant
const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex'
	},
	appBar: {
		backgroundColor: theme.palette.background.default
	},
	appBarWidth: {
		transition: theme.transitions.create('width'),
		backgroundColor: theme.palette.background.default
	},
	content: {
		...theme.typography.mainContent,
		backgroundColor: theme.palette.primary.light,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		[theme.breakpoints.up('md')]: {
			marginLeft: -(drawerWidth - 20),
			width: `calc(100% - ${drawerWidth}px)`
		},
		[theme.breakpoints.down('md')]: {
			marginLeft: '20px',
			width: `calc(100% - ${drawerWidth}px)`,
			padding: '16px'
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '10px',
			width: `calc(100% - ${drawerWidth}px)`,
			padding: '16px',
			marginRight: '10px'
		}
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		}),
		marginLeft: 0,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		[theme.breakpoints.down('md')]: {
			marginLeft: '20px'
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '10px'
		}
	}
}));

const MainLayout = () => {
	const classes = useStyles();
	const theme = useTheme();
	const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

	// Handle left drawer
	const leftDrawerOpened = useSelector(selectMenu);
	const dispatch = useDispatch();
	const handleLeftDrawerToggle = () => {
		dispatch(setMenu(!leftDrawerOpened));
	};

	React.useEffect(() => {
		dispatch(setMenu(!matchDownMd));
	}, [dispatch, matchDownMd]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			{/* header */}
			<AppBar
				enableColorOnDark
				position='fixed'
				color='inherit'
				elevation={0}
				className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}>
				<Toolbar>
					<Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
				</Toolbar>
			</AppBar>

			{/* drawer */}
			<Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

			{/* main content */}
			<main
				className={clsx([
					classes.content,
					{
						[classes.contentShift]: leftDrawerOpened
					}
				])}>
				{/* breadcrumb */}
				<Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
				<Outlet />
			</main>
			<Feedback />
		</div>
	);
};

export default MainLayout;
