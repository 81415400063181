import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { authFire, dbFirestore } from '../appFirebase';
import { updateDocToSubCollection, setDocToSubCollection } from './dbUtils';

// User Events
export const getUserEvents = async () => {
	const userId = authFire.currentUser.uid;
	const querySnapshot = await getDocs(collection(dbFirestore, `users/${userId}/events`));

	const userEvents = [];
	querySnapshot.forEach(userEvent => {
		userEvents.push({ ...userEvent.data() });
	});
	return userEvents;
};

// get User Event
export const getUserEvent = async eventId => {
	const userId = authFire.currentUser.uid;
	const userEventsRef = doc(dbFirestore, `users/${userId}/events/${eventId}`);

	const docSnap = await getDoc(userEventsRef);
	return docSnap.exists() ? docSnap.data() : null;
};

export const registerUserToEvent = async (eventId, eventData) => {
	const userId = authFire.currentUser.uid;
	await setDocToSubCollection(`users/${userId}/events/${eventId}`, { userId, eventId, ...eventData });
	return { userId, eventId, ...eventData };
};

export const updateUserEvent = async (eventId, eventData) => {
	const userId = authFire.currentUser.uid;
	await updateDocToSubCollection(`users/${userId}/events/${eventId}`, { userId, eventId, ...eventData });
	return { userId, eventId, ...eventData };
};

export const updateUserEventFeedback = async (eventId, feedback) => {
	const userId = authFire.currentUser.uid;
	await updateDocToSubCollection(`users/${userId}/events/${eventId}`, { userId, eventId, feedback });
	return { userId, eventId, feedback };
};
