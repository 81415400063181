import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS, hasUserRegisteredToEvent, isComplete } from 'slices/constants';
import {
	fetchGuestDetails,
	addGuestUser,
	registerGuestUserToEvent,
	guestAlreadyRegisteredToEvent,
	updateGuestUser,
	addGuestUserEventFeedback
} from './guestAPI';

const initialState = {
	info: {},
	event: {}
};

// thunk & service
export const getGuestInfoAsync = createAsyncThunk('guest/fetchGuestUser', async guestInfo => {
	const { email, phoneNumber } = guestInfo;
	const guestData = await fetchGuestDetails(email, phoneNumber);
	// The value we return becomes the `fulfilled` action payload
	return guestData;
});

export const addGuestUserAsync = createAsyncThunk('guest/addGuestUser', async guest => {
	const guestData = await addGuestUser({ ...guest, email: guest.email.toLowerCase() });
	// The value we return becomes the `fulfilled` action payload
	return guestData;
});

export const updateGuestUserAsync = createAsyncThunk('guest/updateGuestUser', async guestInfo => {
	const { guestId, ...guest } = guestInfo;
	await updateGuestUser(guestId, { ...guest, email: guest.email.toLowerCase() });
	// The value we return becomes the `fulfilled` action payload
	return guestInfo;
});

export const checkIfGuestAlreadyRegisteredToEventAsync = createAsyncThunk(
	'guest/guestAlreadyRegisteredToEvent',
	async guestEventData => {
		const { guestId, eventId } = guestEventData;
		const guestEventDetails = await guestAlreadyRegisteredToEvent(guestId, eventId);
		// The value we return becomes the `fulfilled` action payload
		const alreadyRegistered = guestEventDetails?.eventId === eventId;
		return { alreadyRegistered, ...guestEventDetails };
	}
);

export const registerGuestUserToEventAsync = createAsyncThunk(
	'guest/registerGuestUserToEvent',
	async registrationData => {
		const { guestId, eventId, data } = registrationData;
		const guestData = await registerGuestUserToEvent(guestId, eventId, data);
		// The value we return becomes the `fulfilled` action payload
		return guestData;
	}
);

export const unregisterGuestUserToEventAsync = createAsyncThunk(
	'guest/unregisterGuestUserToEvent',
	async registrationData => {
		const { guestId, eventId } = registrationData;
		const guestData = await registerGuestUserToEvent(guestId, eventId);
		// The value we return becomes the `fulfilled` action payload
		return guestData;
	}
);

export const addGuestUserEventFeedbackAsync = createAsyncThunk(
	'guest/addGuestUserEventFeedback',
	async registrationData => {
		const { guestId, eventId, feedback } = registrationData;
		const guestEventFeedback = await addGuestUserEventFeedback(guestId, eventId, feedback);
		// The value we return becomes the `fulfilled` action payload
		return guestEventFeedback;
	}
);

export const guestSlice = createSlice({
	name: 'guest',
	initialState,
	reducers: {
		setGuest: (state, action) => {
			// TODO: setter
		}
	},

	extraReducers: builder => {
		builder
			.addCase(getGuestInfoAsync.pending, state => {
				state.infoStatus = API_STATUS.PENDING;
			})
			.addCase(getGuestInfoAsync.rejected, state => {
				state.infoStatus = API_STATUS.REJECTED;
			})
			.addCase(getGuestInfoAsync.fulfilled, (state, action) => {
				state.info = action.payload;
				state.infoStatus = API_STATUS.COMPLETED;
			})
			.addCase(addGuestUserAsync.pending, state => {
				state.infoStatus = API_STATUS.PENDING;
			})
			.addCase(addGuestUserAsync.rejected, state => {
				state.infoStatus = API_STATUS.REJECTED;
			})
			.addCase(addGuestUserAsync.fulfilled, (state, action) => {
				state.info = action.payload;
				state.infoStatus = API_STATUS.COMPLETED;
			})
			.addCase(updateGuestUserAsync.pending, state => {
				state.infoStatus = API_STATUS.PENDING;
			})
			.addCase(updateGuestUserAsync.rejected, state => {
				state.infoStatus = API_STATUS.REJECTED;
			})
			.addCase(updateGuestUserAsync.fulfilled, (state, action) => {
				state.info = action.payload;
				state.infoStatus = API_STATUS.COMPLETED;
			})
			.addCase(registerGuestUserToEventAsync.pending, state => {
				state.eventRegisterStatus = API_STATUS.PENDING;
			})
			.addCase(registerGuestUserToEventAsync.rejected, state => {
				state.eventRegisterStatus = API_STATUS.REJECTED;
			})
			.addCase(registerGuestUserToEventAsync.fulfilled, (state, action) => {
				state.event = action.payload;
				state.eventRegisterStatus = API_STATUS.COMPLETED;
			})
			.addCase(checkIfGuestAlreadyRegisteredToEventAsync.rejected, state => {
				state.eventFeedbackStatus = API_STATUS.REJECTED;
			})
			.addCase(checkIfGuestAlreadyRegisteredToEventAsync.fulfilled, (state, action) => {
				state.event = action.payload;
				state.eventFeedbackStatus = API_STATUS.COMPLETED;
			})
			.addCase(addGuestUserEventFeedbackAsync.pending, state => {
				state.eventFeedbackStatus = API_STATUS.PENDING;
			})
			.addCase(addGuestUserEventFeedbackAsync.rejected, state => {
				state.eventFeedbackStatus = API_STATUS.REJECTED;
			})
			.addCase(addGuestUserEventFeedbackAsync.fulfilled, (state, action) => {
				state.event.feedback = action.payload;
				state.eventFeedbackStatus = API_STATUS.COMPLETED;
			});
	}
});

export const selectGuest = state => state.guest.info;
export const selectGuestId = state => state.guest.info?.gid;
export const isGuestRegistrationToEvent = state => hasUserRegisteredToEvent(state.guest.event?.status);
export const isGuestEventFeedbackComplete = state => !!state.guest.event?.feedback;

export const { setGuest } = guestSlice.actions;

export default guestSlice.reducer;
