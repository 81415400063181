// assets
import { IconCalendarEvent } from '@tabler/icons';

const adminPages = {
	id: 'administrator',
	title: 'Admin Dashboard',
	caption: 'Admin Management',
	type: 'group',
	admin: true,
	children: [
		{
			id: 'events',
			title: 'Events',
			type: 'item',
			url: '/admin/events',
			icon: IconCalendarEvent,
			breadcrumbs: false
		}
	]
};

export default adminPages;
