import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import config from 'config';

// material-ui
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';

// assets
import { LockOpen } from '@mui/icons-material';

// style const
const useStyles = makeStyles(theme => ({
	profileChip: {
		height: '48px',
		alignItems: 'center',
		borderRadius: '27px',
		transition: 'all .2s ease-in-out',
		borderColor: theme.palette.primary.light,
		backgroundColor: theme.palette.primary.light,
		'&[aria-controls="menu-list-grow"], &:hover': {
			borderColor: theme.palette.primary.main,
			background: `${theme.palette.primary.main}!important`,
			color: theme.palette.primary.light,
			'& svg': {
				stroke: theme.palette.primary.light
			}
		},
		cursor: 'pointer'
	},
	profileLabel: {
		fontWeight: 600,
		padding: '16px'
	}
}));

const ProfileSection = () => {
	const classes = useStyles();

	return (
		<>
			<Chip
				classes={{ label: classes.profileLabel }}
				className={classes.profileChip}
				icon={<LockOpen stroke={1.5} size='1rem' />}
				label='Sign In'
				variant='outlined'
				color='primary'
				component={Link}
				to='auth/login'
			/>
		</>
	);
};

export default ProfileSection;
