import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { useSelector } from 'react-redux';
import { selectIsAdmin } from 'slices/user/userSlice';
import config from 'config';

const AdminGaurd = ({ children }) => {
	const { isLoggedIn } = useAuth();
	const isAdmin = useSelector(selectIsAdmin);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const canAccessAdmin = pathname.startsWith('/admin') && isAdmin;

	useEffect(() => {
		if (!isLoggedIn) {
			navigate('/auth/login', { replace: true });
		} else if (!canAccessAdmin) {
			navigate(config.defaultHomePath, { replace: true });
		}
	}, [isLoggedIn, canAccessAdmin]);

	return children;
};

AdminGaurd.propTypes = {
	children: PropTypes.node
};

export default AdminGaurd;
