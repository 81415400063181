import { useRef, useEffect, useState } from 'react';

import { CanvasContext } from "contexts/CanvasContext";
import useResponsiveSize from 'hooks/useResponsiveSize';
import Wave from '../wave';

const Canvas = () => {
	const canvasRef = useRef(null);
	const { width, height } = useResponsiveSize();
	const [context, setContext] = useState();

	useEffect(() => {
		const ctx = canvasRef?.current?.getContext('2d');
		if (ctx) setContext(ctx);
	}, []);

	return (
		<>
			<CanvasContext.Provider value={{ context: context }}>
				<canvas id='canvas' ref={canvasRef} width={width} height={height}></canvas>
				<Wave />
			</CanvasContext.Provider>
		</>
	);
};

export default Canvas;
