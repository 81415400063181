import React from 'react';

// project imports
import HomeLayout from 'layout/PublicLayout/Home';
import HomePage from 'views/pages/home';
import GuestGuard from 'routes/route-guard/GuestGuard';

const HomeRoutes = {
	path: '/',
	element: (
		<GuestGuard>
			<HomeLayout />
		</GuestGuard>
	),
	children: [
		{
			path: '/',
			element: <HomePage />
		}
	]
};

export default HomeRoutes;
