import React from 'react';
import Typography from '@mui/material/Typography';

const Copyright = () => (
	<Typography variant='body2' color='textSecondary' align='center'>
		Copyright © Rawyy Inc.
		<span> </span>
		{new Date().getFullYear()}
	</Typography>
);

export default Copyright;
