import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { shuffle as _shuffle } from 'lodash-es';
import { API_STATUS, isComplete } from 'slices/constants';
import { fetchDemographics, fetchUserDemographics, updateUserDemographics } from './demographicsAPI';

const initialState = {
	questions: [],
	userDemographics: []
};

// thunk & service
export const getDemographicsAsync = createAsyncThunk('demographics/fetchDemographics', async () => {
	const demographicsData = await fetchDemographics();
	return demographicsData;
});

export const getUserDemographicsAsync = createAsyncThunk('demographics/fetchUserDemographics', async demographicId => {
	const userDemographicsData = await fetchUserDemographics(demographicId);
	return userDemographicsData;
});

export const updateUserDemographicsAsync = createAsyncThunk(
	'demographics/updateUserDemographics',
	async updateDemographics => {
		const { currentDemographicId, answers } = updateDemographics;
		const userDemographicsData = await updateUserDemographics(currentDemographicId, answers);
		return userDemographicsData;
	}
);

export const demographicsSlice = createSlice({
	name: 'demographics',
	initialState,
	reducers: {
		setDemographics: (state, action) => {
			// TODO
		}
	},

	extraReducers: builder => {
		builder
			.addCase(getDemographicsAsync.pending, state => {
				state.demographicsStatus = API_STATUS.PENDING;
			})
			.addCase(getDemographicsAsync.rejected, state => {
				state.demographicsStatus = API_STATUS.REJECTED;
			})
			.addCase(getDemographicsAsync.fulfilled, (state, action) => {
				state.questions = _shuffle(action.payload?.questions ?? []);
				state.demographicId = action.payload?.demographicId;
				state.demographicsStatus = API_STATUS.COMPLETED;
			})
			.addCase(getUserDemographicsAsync.pending, state => {
				state.userDemographicsStatus = API_STATUS.PENDING;
			})
			.addCase(getUserDemographicsAsync.rejected, state => {
				state.userDemographicsStatus = API_STATUS.REJECTED;
			})
			.addCase(getUserDemographicsAsync.fulfilled, (state, action) => {
				state.userDemographics = action.payload;
				state.userDemographicsStatus = API_STATUS.COMPLETED;
			})
			.addCase(updateUserDemographicsAsync.pending, state => {
				state.updatedDemographicsStatus = API_STATUS.PENDING;
			})
			.addCase(updateUserDemographicsAsync.rejected, state => {
				state.updatedDemographicsStatus = API_STATUS.REJECTED;
			})
			.addCase(updateUserDemographicsAsync.fulfilled, (state, action) => {
				state.userDemographics = action.payload;
				state.updatedDemographicsStatus = API_STATUS.COMPLETED;
			});
	}
});

export const selectUserDemographicsComplete = state => !!state.demographics.userDemographics?.modifiedDate;
export const selectDemographicId = state => state.demographics.demographicId;
export const selectDemographicQuestions = state => state.demographics.questions;
export const selectDemographicsLoaded = state => isComplete(state.demographics.demographicsStatus);

export const selectUserDemographics = state => state.demographics.userDemographics;
export const selectUserDemographicsLoaded = state => isComplete(state.demographics.userDemographicsStatus);

export const { setDemographics } = demographicsSlice.actions;

export default demographicsSlice.reducer;
