import React, { lazy } from 'react';

// project imports
import Loadable from 'components/common/Loadable';
import PublicLayout from 'layout/PublicLayout/Public';
import PublicGuard from 'routes/route-guard/PublicGuard';

// pages
const EventRegistrationPage = Loadable(lazy(() => import('views/pages/events/eventRegistrationPage')));
const EventFeedbackPage = Loadable(lazy(() => import('views/pages/events/eventFeedbackPage')));

const PublicRoutes = {
	path: '/',
	element: (
		<PublicGuard>
			<PublicLayout />
		</PublicGuard>
	),
	children: [
		{
			path: '/public/event/registration/:eventId',
			element: <EventRegistrationPage />
		},
		{
			path: '/public/event/feedback/:eventId',
			element: <EventFeedbackPage />
		}
	]
};

export default PublicRoutes;
