import { updateProfile } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { dbFirestore, authFire } from '../appFirebase';
import { setDocToColMerge, updateDocToCollection } from './dbUtils';
import { getCurrentDate } from 'utils/dateUtil';

// create an user in and store it at users/id path
export const doCreateUser = (userId, data) => setDocToColMerge(userId, 'users', data);
export const doUpdateUser = (userId, data) => updateDocToCollection(userId, 'users', data);

// get User
export const getUser = userId => {
	const userRef = doc(dbFirestore, 'users', userId);
	return getDoc(userRef).then(docSnap => {
		if (docSnap.exists()) {
			return { ...docSnap.data(), userId };
		}
		return null;
	});
};

export const doUpdateUserProfile = ({ displayName, photoURL }) =>
	updateProfile(authFire.currentUser, {
		displayName,
		photoURL
	});

// record user demographics completed date
export const doMarkUserDemographicsComplete = async userId => {
	const demographicsDate = getCurrentDate();

	const userRef = doc(dbFirestore, 'users', userId);
	await setDoc(userRef, { demographicsDate }, { merge: true });

	return {
		demographicsDate
	};
};

export const doUpdateUserLastAssessmentDate = async userId => {
	const lastAssessmentDate = getCurrentDate();

	const userRef = doc(dbFirestore, 'users', userId);
	await setDoc(userRef, { lastAssessmentDate }, { merge: true });

	return {
		lastAssessmentDate
	};
};
