import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { orderBy as _orderBy, sortedUniq as _sortedUniq } from 'lodash-es';
import { API_STATUS, CATEGORY_LIST, EVENT_LEVELS, EVENT_STATUS, EVENT_STATUS_LIST, isComplete } from 'slices/constants';
import { fetchAudience } from './audienceAPI';

const initialState = {
	audienceLoaded: false,
	audienceList: [],
	levelsList: [...EVENT_LEVELS],
	categoryList: [...CATEGORY_LIST],
	statusList: [...EVENT_STATUS_LIST]
};

// const
export const AUDIENCE_THEMES = {
	PARENTING: 'Parenting',
	COUPLES: 'Couples'
};

// thunk & service
export const getAudienceAsync = createAsyncThunk('audience/fetchAudience', async () => {
	const audienceData = await fetchAudience();
	return audienceData;
});

export const audienceSlice = createSlice({
	name: 'audience',
	initialState,
	reducers: {
		setAudience: (state, action) => {
			// TODO
		}
	},

	extraReducers: builder => {
		builder
			.addCase(getAudienceAsync.pending, state => {
				state.status = API_STATUS.PENDING;
			})
			.addCase(getAudienceAsync.rejected, state => {
				state.status = API_STATUS.REJECTED;
			})
			.addCase(getAudienceAsync.fulfilled, (state, action) => {
				action.payload.forEach(aud => {
					aud.themes = _sortedUniq(aud.themes);
				});
				state.audienceList = _orderBy(action.payload, ['name'], ['asc']);
				state.audienceLoaded = true;
				state.status = API_STATUS.COMPLETED;
			});
	}
});

export const selectAudience = state => state.audience.audienceList;
export const selectAudienceLoaded = state => isComplete(state.audience.status);
export const selectThemesByName = name => state =>
	state.audience.audienceList?.find(al => al.name.toLowerCase() === name.toLowerCase())?.themes || ['None'];

export const selectCategory = state => state.audience.categoryList;
export const selectStatus = state => state.audience.statusList;
export const selectLevel = state => state.audience.levelsList;

export const selectAudienceTheme = state => audience =>
	state.audience.audienceList.find(au => au.name === audience)?.themes || ['None'];

export const { setAudience } = audienceSlice.actions;

export default audienceSlice.reducer;
