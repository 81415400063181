import React, { lazy } from 'react';

// project imports
import AdminGaurd from 'routes/route-guard/AdminGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/common/Loadable';

// Pages
const EventsManagementPage = Loadable(lazy(() => import('views/pages/admin/events')));

const MainRoutes = {
	path: '/',
	element: (
		<AdminGaurd>
			<MainLayout />
		</AdminGaurd>
	),
	children: [
		{
			path: '/admin/events',
			element: <EventsManagementPage />
		}
	]
};

export default MainRoutes;
