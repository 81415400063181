import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from 'slices/constants';
import { updateUserToSessionStorage } from 'utils/userUtil';
import { attemptCreateUser, attemptUpdateUser, fetchUserInfo, hasUserAlreadyRegisteredToEvent } from './userAPI';

const session = sessionStorage.getItem('user');
let initialState = {
	isLoggedIn: false,
	isInitialized: true,
	info: {
		notifications: { email: true, text: false, whatsapp: false },
		isAdmin: false
	}
};
if (session) {
	initialState = JSON.parse(session);
}

export const getUserInfoAsync = createAsyncThunk('user/fetchUserInfo', async userId => {
	const user = await fetchUserInfo(userId);
	return user && { user, isLoggedIn: true };
});

export const createUserAsync = createAsyncThunk('user/attemptCreateUser', async newUser => {
	await attemptCreateUser(newUser);
	return { user: newUser, isLoggedIn: true };
});

export const updateUserAsync = createAsyncThunk('user/attemptUpdateUser', async updatedUser => {
	const { userId, ...data } = updatedUser;
	await attemptUpdateUser(userId, data);
	return { updatedUser };
});

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.info = action.payload.user;
			state.isLoggedIn = action.payload.isLoggedIn;
			state.isInitialized = true;
			updateUserToSessionStorage(state);
		},
		setLoggedIn: (state, action) => {
			state.isLoggedIn = action.payload;
		},
		onLogout: (state, action) => {
			state = initialState;
		},
		updateUser: (state, action) => {
			const { notifications, ...rest } = action.payload;
			state = {
				...state,
				notifications: { ...state.notifications, ...notifications },
				...rest
			};
			updateUserToSessionStorage(state);
		},
		setDemographicsComplete: (state, action) => {
			state.demographicsDate = action.payload;
		},
		setLastAssessmentDate: (state, action) => {
			state.lastAssessmentDate = action.payload;
		}
	},

	extraReducers: builder => {
		builder
			.addCase(createUserAsync.fulfilled, (state, action) => {
				state.info = action.payload.user;
				state.isLoggedIn = action.payload.isLoggedIn;
				state.isInitialized = true;
				updateUserToSessionStorage(state);
			})
			.addCase(createUserAsync.rejected, state => {
				state.status = API_STATUS.REJECTED;
			})
			.addCase(updateUserAsync.fulfilled, (state, action) => {
				state.info = { ...state.info, ...action.payload.updatedUser };
				updateUserToSessionStorage(state);
			})
			.addCase(updateUserAsync.rejected, state => {
				state.status = API_STATUS.REJECTED;
			});
	}
});

export const selectUser = state => state.user;
export const isLoggedIn = state => state.user.loggedIn;
export const selectIsAdmin = state => state.user.info?.isAdmin;
export const isDemographicsComplete = state => state.user?.loggedIn && !!state.user?.demographicsDate;

export const selectUserNotifications = state => state.user.info?.notifications || {};
export const selectUserPreferences = state => state.user.info?.preferences || { parents: [], couples: [] };

export const { setUser, setLoggedIn, onLogout, updateUser, setDemographicsComplete, setLastAssessmentDate } =
	userSlice.actions;

export default userSlice.reducer;
