import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS, hasUserRegisteredToEvent, isCompleteOrPending } from 'slices/constants';
import { addUserEventFeedback, fetchUserEvents, registerUserToEvent, unregisterUserToEvent } from './userEventsAPI';

const initialState = {
	events: [],
	status: ''
};

// thunk & service
export const getUserEventsAsync = createAsyncThunk('userEvents/fetchUserEvents', async () => {
	const userEvents = await fetchUserEvents();
	return userEvents;
});

export const registerUserToEventAsync = createAsyncThunk('userEvents/registerUserToEvent', async eventId => {
	const eventData = await registerUserToEvent(eventId);
	// The value we return becomes the `fulfilled` action payload
	return eventData;
});

export const unregisterUserToEventAsync = createAsyncThunk(
	'userEvents/unregisterUserToEvent',
	async registrationData => {
		const { eventId } = registrationData;
		const eventData = await unregisterUserToEvent(eventId);
		// The value we return becomes the `fulfilled` action payload
		return eventData;
	}
);

export const addUserEventFeedbackAsync = createAsyncThunk('guest/addUserEventFeedback', async registrationData => {
	const { eventId, feedback } = registrationData;
	const eventFeedback = await addUserEventFeedback(eventId, feedback);
	// The value we return becomes the `fulfilled` action payload
	return eventFeedback;
});

export const userEventsSlice = createSlice({
	name: 'userEvents',
	initialState,
	reducers: {
		setUserEvents: (state, action) => {
			// TODO: setter
		}
	},

	extraReducers: builder => {
		builder
			.addCase(getUserEventsAsync.pending, state => {
				state.status = API_STATUS.PENDING;
			})
			.addCase(getUserEventsAsync.rejected, state => {
				state.status = API_STATUS.REJECTED;
			})
			.addCase(getUserEventsAsync.fulfilled, (state, action) => {
				state.events = [...action.payload];
				state.status = API_STATUS.COMPLETED;
			})
			.addCase(registerUserToEventAsync.pending, state => {
				state.registeredStatus = API_STATUS.PENDING;
			})
			.addCase(registerUserToEventAsync.rejected, state => {
				state.registeredStatus = API_STATUS.REJECTED;
			})
			.addCase(registerUserToEventAsync.fulfilled, (state, action) => {
				state.events.push(action.payload);
				state.registeredStatus = API_STATUS.COMPLETED;
			})
			.addCase(unregisterUserToEventAsync.pending, state => {
				state.registeredStatus = API_STATUS.PENDING;
			})
			.addCase(unregisterUserToEventAsync.rejected, state => {
				state.registeredStatus = API_STATUS.REJECTED;
			})
			.addCase(unregisterUserToEventAsync.fulfilled, (state, action) => {
				state.events = state.events.filter(ue => ue.eventId === action.payload.evenId);
				state.registeredStatus = API_STATUS.COMPLETED;
			})
			.addCase(addUserEventFeedbackAsync.rejected, state => {
				state.feedbackStatus = API_STATUS.REJECTED;
			})
			.addCase(addUserEventFeedbackAsync.fulfilled, (state, action) => {
				state.events = state.events.map(event => {
					if (event.eventId !== action.payload.eventId) {
						return event;
					}

					return Object.assign({}, event, {
						...action.payload
					});
				});
				state.feedbackStatus = API_STATUS.COMPLETED;
			});
	}
});

export const selectUserEvents = state => state.userEvents.events;
export const selectUserEventsLoaded = state => {
	return isCompleteOrPending(state.userEvents.status);
};
export const selectHasUserRegisteredToEvent = eventId => state => {
	const exists = state.userEvents?.events.find(e => e.eventId === eventId);
	return hasUserRegisteredToEvent(exists?.status);
};
export const isUserEventFeedbackComplete = eventId => state => {
	const exists = state.userEvents?.events.find(e => e.eventId === eventId);
	return !!exists?.feedback;
};

export const { setUserEvents } = userEventsSlice.actions;

export default userEventsSlice.reducer;
