import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/styles';
import {
	Avatar,
	Card,
	CardContent,
	Chip,
	ClickAwayListener,
	Divider,
	Grid,
	List,
	ListItemIcon,
	ListItemText,
	Paper,
	Popper,
	Switch,
	Typography
} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'components/cards/MainCard';
import Transitions from 'components/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import { selectCustomization } from 'slices/customization/customizationSlice';
import { selectUser } from 'slices/user/userSlice';

// hooks
import useAuth from 'hooks/useAuth';

// assets
import { IconLogout, IconSettings, IconUserPlus } from '@tabler/icons';
import { useStyles } from './profileStyles';

const ProfileSection = () => {
	const classes = useStyles();
	const theme = useTheme();
	const { doLogout } = useAuth();

	const customization = useSelector(selectCustomization);
	const user = useSelector(selectUser);

	// const [notification, setNotification] = useState(false);
	const [selectedIndex] = useState(1);
	const [open, setOpen] = useState(false);
	const prevOpen = useRef(open);
	const anchorRef = useRef(null);

	const handleToggle = () => setOpen(prevOpen => !prevOpen);

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	return (
		<>
			<Chip
				classes={{ label: classes.profileLabel }}
				className={classes.profileChip}
				icon={
					<Avatar
						src={user?.info?.photoURL || ''}
						className={classes.headerAvatar}
						ref={anchorRef}
						aria-controls={open ? 'menu-list-grow' : undefined}
						aria-haspopup='true'
						color='inherit'
					/>
				}
				label={<IconSettings stroke={1.5} size='1.5rem' color={theme.palette.primary.main} />}
				variant='outlined'
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup='true'
				onClick={handleToggle}
				color='primary'
			/>
			<Popper
				placement='bottom-end'
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 14]
							}
						}
					]
				}}>
				{({ TransitionProps }) => (
					<Transitions in={open} {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MainCard
									border={false}
									elevation={16}
									content={false}
									boxShadow
									shadow={theme.shadows[16]}>
									<CardContent className={classes.cardContent}>
										<Grid container direction='column' spacing={0}>
											<Grid item className={classes.flex}>
												<Typography variant='h4'>Hi,</Typography>
												<Typography component='span' variant='h4' className={classes.name}>
													{user?.info?.displayName || ''}
												</Typography>
											</Grid>
											{
												<Grid item>
													<Typography variant='subtitle2'>Project Admin</Typography>
												</Grid>
											}
										</Grid>
										<Divider sx={{ my: 1 }} />
										<PerfectScrollbar className={classes.ScrollHeight}>
											<UpgradePlanCard />
											<Divider />
											{/* <Card className={classes.card}>
												<CardContent>
													<Grid container spacing={3} direction='column'>
														<Grid item>
															<Grid
																item
																container
																alignItems='center'
																justifyContent='space-between'>
																<Grid item>
																	<Typography variant='subtitle1'>
																		Allow Notifications
																	</Typography>
																</Grid>
																<Grid item>
																	<Switch
																		checked={notification}
																		onChange={e =>
																			setNotification(e.target.checked)
																		}
																		name='sdm'
																		size='small'
																	/>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</CardContent>
											</Card>
											<Divider /> */}
											<List component='nav' className={classes.navContainer}>
												<ListItemButton
													className={classes.listItem}
													sx={{ borderRadius: `${customization.borderRadius}px` }}
													selected={selectedIndex === 4}
													onClick={handleClose}
													component={Link}
													to='/profile'>
													<ListItemIcon>
														<IconUserPlus stroke={1.5} size='1.3rem' />
													</ListItemIcon>
													<ListItemText
														primary={<Typography variant='body2'>Profile</Typography>}
													/>
												</ListItemButton>
												<ListItemButton
													className={classes.listItem}
													sx={{ borderRadius: `${customization.borderRadius}px` }}
													selected={selectedIndex === 4}
													onClick={handleClose}
													component={Link}
													to='/preferences'>
													<ListItemIcon>
														<IconSettings stroke={1.5} size='1.3rem' />
													</ListItemIcon>
													<ListItemText
														primary={<Typography variant='body2'>Preferences</Typography>}
													/>
												</ListItemButton>
												<ListItemButton
													className={classes.listItem}
													sx={{ borderRadius: `${customization.borderRadius}px` }}
													selected={selectedIndex === 4}
													onClick={doLogout}>
													<ListItemIcon>
														<IconLogout stroke={1.5} size='1.3rem' />
													</ListItemIcon>
													<ListItemText
														primary={<Typography variant='body2'>Logout</Typography>}
													/>
												</ListItemButton>
											</List>
										</PerfectScrollbar>
									</CardContent>
								</MainCard>
							</ClickAwayListener>
						</Paper>
					</Transitions>
				)}
			</Popper>
		</>
	);
};

export default ProfileSection;
