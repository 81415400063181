import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from './routes';

//auth provider
import { FirebaseProvider } from 'contexts/FirebaseContext';

// defaultTheme
import themes from './themes';
import { selectCustomization } from 'slices/customization/customizationSlice';

// project imports
import NavigationScroll from './layout/NavigationScroll';
// import TimeoutDialog from 'components/Timeout/TimeoutDialog';

// intl
import { IntlProvider } from 'react-intl';
// import { tomorrow } from 'utils/dateUtil';
const messagesInEnglish = {
	parents: 'Parents'
};
const messagesInAr = {
	parents: 'الآباء'
};

const App = () => {
	const customization = useSelector(selectCustomization);

	return (
		<StyledEngineProvider injectFirst>
			<IntlProvider messages={messagesInEnglish} locale='en' defaultLocale='en'>
				<ThemeProvider theme={themes(customization)}>
					{/* <TimeoutDialog interval={3} end={tomorrow} /> */}
					<CssBaseline />
					<NavigationScroll>
						<FirebaseProvider>
							<Routes />
						</FirebaseProvider>
					</NavigationScroll>
				</ThemeProvider>
			</IntlProvider>
		</StyledEngineProvider>
	);
};


export default App;
