import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

const PublicGuard = ({ children }) => {
	const { isLoggedIn } = useAuth();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	useEffect(() => {
		if (isLoggedIn && pathname.startsWith('/public')) {
			navigate(pathname.replace('/public', ''), { replace: true });
		}
	}, [isLoggedIn, navigate, pathname]);

	return children;
};

PublicGuard.propTypes = {
	children: PropTypes.node
};

export default PublicGuard;
