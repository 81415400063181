import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

// project imports
import LogoSection from './LogoSection';
import AuthSection from './AuthSection';

// style constant
const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1
	},
	headerAvatar: {
		...theme.typography.commonAvatar,
		...theme.typography.mediumAvatar,
		transition: 'all .2s ease-in-out',
		background: theme.palette.primary.light,
		color: theme.palette.primary.dark,
		'&:hover': {
			background: theme.palette.primary.dark,
			color: theme.palette.primary.light
		}
	},
	boxContainer: {
		width: '228px',
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			width: 'auto'
		}
	}
}));

const Header = () => {
	const classes = useStyles();

	return (
		<>
			{/* logo & toggler button */}
			<div className={classes.boxContainer}>
				<Box component='span' sx={{ display: { md: 'block' }, flexGrow: 1 }}>
					<LogoSection />
				</Box>
			</div>

			{/* header search */}
			<div className={classes.grow} />
			<div className={classes.grow} />

			{/* notification & profile */}
			<AuthSection />
		</>
	);
};

Header.propTypes = {
	handleLeftDrawerToggle: PropTypes.func
};

export default Header;
