import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { groupBy as _groupBy, orderBy as _orderBy, sortedUniq as _sortedUniq } from 'lodash-es';
import { API_STATUS, isComplete, ROLES } from 'slices/constants';
import { fetchEmployees } from './employeesAPI';

const initialState = {
	list: {
		moderator: [],
		practitioner: []
	}
};

// thunk & service
export const getEmployeesAsync = createAsyncThunk('audience/fetchEmployees', async () => {
	const employeesData = await fetchEmployees();
	// The value we return becomes the `fulfilled` action payload
	return employeesData;
});

export const employeesSlice = createSlice({
	name: 'employees',
	initialState,
	reducers: {
		setEmployees: (state, action) => {
			// TODO: _.groupBy(arr, "type")
		}
	},

	extraReducers: builder => {
		builder
			.addCase(getEmployeesAsync.pending, state => {
				state.status = API_STATUS.PENDING;
			})
			.addCase(getEmployeesAsync.rejected, state => {
				state.status = API_STATUS.REJECTED;
			})
			.addCase(getEmployeesAsync.fulfilled, (state, action) => {
				state.list = _groupBy(action.payload, 'role');
				state.status = API_STATUS.COMPLETED;
			});
	}
});

export const selectEmployeesLoaded = state => isComplete(state.employees.status);
const getEmployeeTypeList = (state, type) =>
	state.employees.list[type].filter(p => p.isActive).map(p => ({ id: p.id, fullName: p.fullName }));

export const selectModerators = state => getEmployeeTypeList(state, ROLES.MODERATOR);
export const selectPractitioners = state => getEmployeeTypeList(state, ROLES.PRACTITIONER);
export const selectPractitionerName = id => state =>
	selectEmployeesLoaded(state) && selectPractitioners(state)?.find(p => p.id === id)?.fullName;
export const selectEmployees = state => state.employees.list;

export const { setEmployees } = employeesSlice.actions;

export default employeesSlice.reducer;
