import { createSlice } from "@reduxjs/toolkit";
import config from "config";

const initialState = {
	isOpen: [], // for active default menu
	fontFamily: config.fontFamily,
	borderRadius: config.borderRadius,
	opened: true,
};

export const customizationSlice = createSlice({
	name: "customization",
	initialState,
	reducers: {
		setMenuOpen: (state, action) => {
			state.isOpen = [action.payload];
		},
		setMenu: (state, action) => {
			state.opened = action.payload;
		},
		setFontFamily: (state, action) => {
			state.fontFamily = action.payload;
		},
		setBorderRadius: (state, action) => {
			state.borderRadius = action.payload;
		},
	},
});

export const selectCustomization = state => state.customization;
export const selectBorderRadius = state => state.customization.borderRadius;
export const selectFontFamily = state => {
	let initialFont;
	switch (state.customization.fontFamily) {
		case `'Inter', sans-serif`:
			initialFont = "Inter";
			break;
		case `'Poppins', sans-serif`:
			initialFont = "Poppins";
			break;
		case `'Roboto', sans-serif`:
		default:
			initialFont = "Roboto";
			break;
	}
	return initialFont;
};
export const selectMenu = state => state.customization.opened;
export const selectMenuOpen = state => state.customization.isOpen;

export const { setMenuOpen, setMenu, setFontFamily, setBorderRadius } = customizationSlice.actions;

export default customizationSlice.reducer;
