import { size as _size } from 'lodash-es';
import { dbDemographics } from 'server/database/collections';

export const fetchDemographics = async () => {
	const allDemographics = await dbDemographics.getDemographics();
	return allDemographics;
};

export const fetchUserDemographics = async demographicId => {
	const userDemographics = await dbDemographics.getUserDemographics(demographicId);
	return userDemographics;
};

export const updateUserDemographics = async (demographicId, answers) => {
	const response = await dbDemographics.updateUserDemographics(demographicId, answers);
	return response;
};
