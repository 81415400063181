import { collection, getDocs } from 'firebase/firestore';
import { dbFirestore } from '../appFirebase';


export const getEmployees = async () => {
	const querySnapshot = await getDocs(collection(dbFirestore, 'employees'));
	const employees = [];
	querySnapshot.forEach(emp => {
		employees.push({ id: emp.id, ...emp.data() });
	});
	return employees;
};

