import React, { lazy } from 'react';
import Loadable from 'components/common/Loadable';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import DemoGuard from 'routes/route-guard/DemoGuard';

// login routing
const DemographicsPage = Loadable(lazy(() => import('views/pages/demographics/demographicsPage')));

const LoginRoutes = {
	path: '/',
	element: (
		<DemoGuard noDefaultNav>
			<MinimalLayout />
		</DemoGuard>
	),
	children: [
		{
			path: '/demographics',
			element: <DemographicsPage />
		}
	]
};

export default LoginRoutes;
