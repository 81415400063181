import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

const DemoGuard = ({ children, noDefaultNav }) => {
	const { isLoggedIn } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isLoggedIn) {
			navigate('/auth/login?to=demographics', { replace: true });
		} else if (isLoggedIn && !noDefaultNav) {
			navigate('/demographics', { replace: true });
		}
	}, [isLoggedIn, navigate]);

	return children;
};

DemoGuard.propTypes = {
	children: PropTypes.node
};

export default DemoGuard;
