import React from 'react';

// material-ui
import { useTheme } from '@mui/styles';
import { Drawer, Fab, Grid, IconButton, Tooltip } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import { gridSpacing } from 'utils/constant';

// assets
import { FeedbackRounded } from '@mui/icons-material';
import MainCard from 'components/cards/MainCard';
import AppFeedback from './AppFeedback';

const Feedback = () => {
	const theme = useTheme();

	// drawer on/off
	const [open, setOpen] = React.useState(false);
	const handleToggle = () => {
		setOpen(!open);
	};

	return (
		<>
			<Tooltip title='Feedback'>
				<Fab
					component='div'
					onClick={handleToggle}
					size='medium'
					variant='string'
					color='primary'
					sx={{
						bottom: 0,
						m: 4,
						position: 'fixed',
						right: 20,
						zIndex: theme => theme.zIndex.speedDial,
						boxShadow: theme.shadows[8]
					}}>
					<IconButton color='inherit' size='large' disableRipple>
						<FeedbackRounded />
					</IconButton>
				</Fab>
			</Tooltip>

			<Drawer
				anchor='right'
				onClose={handleToggle}
				open={open}
				PaperProps={{
					sx: {
						width: 350
					}
				}}>
				<PerfectScrollbar component='div'>
					<Grid container spacing={gridSpacing} sx={{ p: 3 }}>
						<Grid item xs={12}>
							<MainCard title='Feedback'>
								<AppFeedback onClose={handleToggle} />
							</MainCard>
						</Grid>
					</Grid>
				</PerfectScrollbar>
			</Drawer>
		</>
	);
};

export default Feedback;
