import { configureStore } from '@reduxjs/toolkit';
import counterReducer from 'slices/counter/counterSlice';
import customizationReducer from 'slices/customization/customizationSlice';
import guestReducer from 'slices/user/guest/guestSlice';
import userReducer from 'slices/user/userSlice';
import userEventsReducer from 'slices/user/events/userEventsSlice';
import eventsReducer from 'slices/events/eventsSlice';
import audienceReducer from 'slices/audience/audienceSlice';
import employeesReducer from 'slices/employees/employeesSlice';
import eventRegistrationReducer from 'slices/eventRegistration/eventRegistrationSlice';
import demographicsReducer from 'slices/demographics/demographicsSlice';
import libraryReducer from 'slices/library/librarySlice';

export const store = configureStore({
	reducer: {
		counter: counterReducer,
		customization: customizationReducer,
		guest: guestReducer,
		user: userReducer,
		userEvents: userEventsReducer,
		events: eventsReducer,
		audience: audienceReducer,
		employees: employeesReducer,
		eventRegistration: eventRegistrationReducer,
		demographics: demographicsReducer,
		library: libraryReducer
	}
});
