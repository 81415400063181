import React, { lazy } from 'react';
import Loadable from 'components/common/Loadable';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import GuestGuard from 'routes/route-guard/GuestGuard';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/auth/Login')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/auth/Register')));

const LoginRoutes = {
	path: '/',
	element: (
		<GuestGuard>
			<MinimalLayout />
		</GuestGuard>
	),
	children: [
		{
			path: '/auth/login',
			element: <AuthLogin />
		},
		{
			path: '/auth/register',
			element: <AuthRegister />
		}
	]
};

export default LoginRoutes;
