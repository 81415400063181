import { orderBy, query, where, collection, limit } from 'firebase/firestore';
import { size as _size } from 'lodash-es';
import { authFire, dbFirestore } from '../appFirebase';
import { getDocFromSubCollection, getQueryData, setDocToSubCollection } from './dbUtils';

export const getDemographics = async () => {
	const demographicsRef = collection(dbFirestore, 'demographics');
	const demographicsQ = query(
		demographicsRef,
		where('status', '==', 'active'),
		orderBy('modifiedDate', 'desc'),
		limit(1)
	);
	const demographicsResults = await getQueryData(demographicsQ, 'demographicId');
	if (_size(demographicsResults)) {
		return demographicsResults[0];
	}
};

export const updateUserDemographics = async (demographicId, answers) => {
	const userId = authFire.currentUser.uid;
	const data = {
		demographicId,
		...answers,
		modifiedDate: new Date().toUTCString()
	};
	await setDocToSubCollection(`users/${userId}/demographics/${demographicId}`, data);
	return data;
};

export const getUserDemographics = async demographicId => {
	const userId = authFire.currentUser.uid;
	const docSnap = await getDocFromSubCollection(`users/${userId}/demographics/${demographicId}`);
	if (docSnap.exists()) {
		return docSnap.data();
	}
};
